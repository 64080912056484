import React from "react";
import "./whatsapp.css";

const Whatsapp = props => {
  let pubURL = process.env.PUBLIC_URL;
  let content = (
    <React.Fragment>
      <a href="https://api.whatsapp.com/send?phone=60164454726&text=Hi%2c%20Virbnb%20Team%2c%20&source=&data=" target="blank">
        <img alt="" className="whatsapp_icon" src={pubURL + "/asset/img/general/whatsapp.png"} />
      </a>
    </React.Fragment>
  );
  return content;
};

export default Whatsapp;
