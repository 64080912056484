import React from "react";
import "./home.css";
import ProductDescription from "../../../component/view/productDescription/productDescription";
import Professional from "../../../component/view/professional/professional";
import ProductPackage from "../../../component/view/productPackage/productPackage";
import Whatsapp from "../../../component/elements/whatsapp/whatsapp";
import UserContact from "../../../component/view/userContact/userContact";

const Home = props => {
  let content = <div />;
  content = (
    <React.Fragment>
      <div className="container-fluid no-padding">
        <div className="row no-padding">
          <div className="col-12 no-padding">
            <div className="home-container">
              <div className="home-sc-container">
                <div className="home-sc-title">Virbnb</div>
                <div className="home-sc-msg">
                  <span className="free-word">free</span> Virtual Office Address
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductDescription />
      <Professional msg="Work Anywhere And Yet Stay Professional!" bgImg="perofbg.jpg" />
      <ProductPackage />
      <Professional msg="We provide 24/7 service to you :)" bgImg="servicebg.jpg" />
      <UserContact />
      <Whatsapp />
    </React.Fragment>
  );

  return content;
};

export default Home;
