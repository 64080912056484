import React, { useState, useEffect } from "react";
import "./userContact.css";
import AlertDialog from "../../../hook/dialog/alertDialog/alertDialog";
import UseFormValidation from "../../../hook/helper/validation/useFormValidation";
import * as firebase from "firebase/app";
import "firebase/firestore";

const INITIAL_STATE = {
  email_form: "",
  name_form: "",
  phone_form: "",
  remark_form: ""
};

const UserContact = props => {
  const { handleInputChange, validateInputs, formValues } = UseFormValidation(INITIAL_STATE);
  const [buttonContent, setButtonContent] = useState();

  let pubURL = process.env.PUBLIC_URL;

  const submitAction = () => {
    // setButtonContent(buttonContentLoading);
    console.log(formValues);
    // const error = validateInputs();
    // if (error.error_message === false) {
    //   //No Error
    //   var db = firebase.firestore();
    //   //db.collection("users")
    //   //.add(formValues)
    //   db.collection("users")
    //     .doc(formValues.email_form)
    //     .set(formValues)
    //     .then(function(docRef) {
    //       setAlertMsg("Your Data Has Been Successfully Saved.");
    //       setAlertOpen(true);
    //       setButtonContent(buttonContentSubmit);
    //     })
    //     .catch(function(error) {
    //       // console.error("Error adding document: ", error);
    //       setAlertMsg("Server Error!");
    //       setAlertOpen(true);
    //       setButtonContent(buttonContentSubmit);
    //     });
    // } else {
    //   setAlertMsg(error.error_message);
    //   setAlertOpen(true);
    // }
  };

  //S ALERT
  // setAlertOpen(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("Thats the first");
  const onAlertOpen = () => {
    setAlertOpen(true);
  };
  const onAlertClose = () => {
    setAlertOpen(false);
  };
  //E ALERT

  const buttonContentLoading = (
    <button className="btn btn-primary" type="button" disabled>
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      <span className="sr-only">Loading...</span>
    </button>
  );
  const buttonContentSubmit = (
    <button className="btn btn-primary" onClick={submitAction}>
      Submit
    </button>
  );

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyAOrsxOgnnGX4ZQBgk6iwGh9HCt-iOOb-w",
      authDomain: "vitual-office.firebaseapp.com",
      databaseURL: "https://vitual-office.firebaseio.com",
      projectId: "vitual-office",
      storageBucket: "vitual-office.appspot.com",
      messagingSenderId: "625403403527",
      appId: "1:625403403527:web:c00b3fd1d57a57fa"
    };
    firebase.initializeApp(firebaseConfig);

    setButtonContent(buttonContentSubmit);
  }, []);

  let content = (
    <React.Fragment>
      <AlertDialog msg={alertMsg} alertOpen={alertOpen} onAlertOpen={onAlertOpen} onAlertClose={onAlertClose} />
      <div className="container-fluid no-padding">
        <div className="row no-padding">
          <div className="row product-pkg-container justify-content-center">
            {/* <div className="col-12 col-sm-8 col-md-7 col-lg-7">
              <div className="contact_container">
                <div className="form-group">
                  <label>Full Name*</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name"
                    name="name_form"
                    onChange={handleInputChange}
                    value={formValues.name_form}
                  />
                </div>
                <div className="form-group">
                  <label>Phone No.*</label>
                  <input type="email" className="form-control" placeholder="Phone No." name="phone_form" onChange={handleInputChange} />
                </div>
                <div className="form-group">
                  <label>Email address*</label>
                  <input type="email" className="form-control" placeholder="Email" name="email_form" onChange={handleInputChange} />
                  <small id="emailHelp" className="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="form-group">
                  <label>Remark (Optional)</label>
                  <textarea className="form-control" rows="3" name="remark_form" onChange={handleInputChange} />
                </div>
                {buttonContent}
              </div>
            </div> */}

            <div className="col-12 col-sm-8 col-md-7 col-lg-7">
              <div className="contact_title">Want a Free Virtual Office Address in Petaling Jaya?</div>
              <div>
                <a href="https://api.whatsapp.com/send?phone=60164454726&text=Hi%2c%20Virbnb%20Team%2c%20&source=&data=" target="blank">
                  <img alt="" className="ask_whatsapp" src={pubURL + "/asset/img/general/ask_us.png"} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return content;
};

export default UserContact;
