import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Props:
// - alertOpen
// - msg
// - onOpenClick

const AlertDialog = props => {
  // const onOpenClick = () => {
  //   // setOpen(true);
  //   props.alertOpen = true;
  // };

  // function handleClose() {
  //   // setOpen(false);
  // }

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={props.onAlertOpen}>
        Open alert dialog
      </Button> */}
      <Dialog open={props.alertOpen} onClose={props.onAlertClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{props.msg}</DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.msg}</DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button onClick={props.onAlertClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
