import React from "react";
import { Link } from "react-router-dom";

const Header = props => {
  let content = (
    // <ul>
    //   <Link to="/">
    //     <li>Home</li>
    //   </Link>
    //   <Link to="/about">
    //     <li>About Us</li>
    //   </Link>
    // </ul>
    <nav className="navbar navbar-expand-sm navbar-light ">
      <Link className="navbar-brand" to="/">
        <img src={process.env.PUBLIC_URL + "/asset/img/general/logo.png"} width="30" height="30" className="d-inline-block align-top" alt="logo" />
        Virbnb
      </Link>
      {/* <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button> */}

      {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="nav navbar-nav ml-auto justify-content-end">
          <li className="nav-item active">
            <Link className="nav-link" to="/about-us">
              About Us
            </Link>
          </li>
          <li className="nav-item active">
            <Link className="nav-link" to="/">
              Home <span className="sr-only">(current)</span>
            </Link>
          </li>
        </ul>
      </div> */}
    </nav>
  );
  return content;
};

export default Header;
