import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "../container/pages/home/home";
import AboutUs from "../container/pages/aboutUs/aboutUs";
import Header from "../container/elements/header/header";
import Footer from "../container/elements/footer/footer";

const Router = props => {
  let content = <div />;
  content = (
    <React.Fragment>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={AboutUs} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
  return content;
};

export default Router;
