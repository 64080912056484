import React from "react";
import "./productDescription.css";

const ProductDescription = props => {
  let pubURL = process.env.PUBLIC_URL;
  let content = (
    <React.Fragment>
      <div className="container-fluid no-padding">
        <div className="row no-padding">
          <div className="col-12 no-padding">
            <div className="product-container">
              <div className="product-title">
                Why Choose <span className="product-name">Virbnb</span> ?
                <hr className="custom-hr" />
                <div className="row product-icon-row">
                  <div className="col-6 col-md-3">
                    <img
                      src={pubURL + "/asset/img/general/office_icon.png"}
                      className="d-inline-block align-top product-icon-img"
                      alt="location icon"
                    />
                    <div className="product-icon-title">Business Address</div>
                    <div className="product-icon-sub-title">Establish your office in a prime location in Petaling Jaya For FREE.</div>
                  </div>
                  <div className="col-6 col-md-3">
                    <img src={pubURL + "/asset/img/general/mail_icon.png"} className="d-inline-block align-top product-icon-img" alt="mail icon" />
                    <div className="product-icon-title">Mail Handling</div>
                    <div className="product-icon-sub-title">Direct your mail to Virbnb's address; have it forwarded or pick it up.</div>
                  </div>
                  <div className="col-6 col-md-3">
                    <img src={pubURL + "/asset/img/general/pay_icon.png"} className="d-inline-block align-top product-icon-img" alt="pay icon" />
                    <div className="product-icon-title">Pay Per Use</div>
                    <div className="product-icon-sub-title">Pay as low as RM2 every time you have a parcel.</div>
                  </div>
                  <div className="col-6 col-md-3">
                    <img src={pubURL + "/asset/img/general/phone_icon.png"} className="d-inline-block align-top product-icon-img" alt="phone icon" />
                    <div className="product-icon-title">Call Forwarding</div>
                    <div className="product-icon-sub-title">Dedicated landline number which forwards calls to your handphone.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return content;
};

export default ProductDescription;
