import React from "react";
import "./professional.css";

const Professional = props => {
  const bgImageStyle = {
    backgroundImage: `url("/asset/img/general/${props.bgImg}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    width: "100%",
    borderBottom: "1px solid #dddddd",
    height: 320
  };
  let content = (
    <React.Fragment>
      <div className="container-fluid no-padding">
        <div className="row no-padding" style={bgImageStyle}>
          <div className="col-12 professional-title ">{props.msg}</div>
        </div>
      </div>
    </React.Fragment>
  );

  return content;
};

export default Professional;
