import React from "react";
import "./productPackage.css";

const ProductPackage = props => {
  let content = (
    <React.Fragment>
      <div className="container-fluid no-padding">
        <div className="row product-pkg-container justify-content-center">
          <div className="col-12 col-sm-8 col-md-7 col-lg-6">
            <div className="package_container">
              <div className="package_container_header">Virbnb's Package</div>
              <div className="package_container_body">
                <div className="package_item_title">Free Registration & Virtual Business Address</div>
              </div>
              <div className="package_container_body">
                <div className="package_item_title">Pay Per Use:</div>
                <ul>
                  <li>Mail Handling Fee (RM2)</li>
                  <li>Shipping Accumulated Mail Out Using DHL (RM7)(*if required)</li>
                </ul>
                <div className="package_item_sub_title">
                  * RM100 would be collected as basic credit (not DEPOSIT), deducted everytime a service is used.
                </div>
                <div className="package_item_sub_title">* We will notify you when mail is received via Whatsapp.</div>
              </div>
              <div className="package_container_body">
                <div className="package_item_title">Optional Service:</div>
                <div>
                  <ul>
                    <li>Fax & Phone Call Forwarding (RM49.90 / monthly)</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  return content;
};

export default ProductPackage;
