import React, { useState, useEffect } from "react";

const UseFormValidation = props => {
  const [formValues, setFormValues] = React.useState(props);

  useEffect(() => {}, []);

  const handleInputChange = event => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    });
    console.log(formValues);
  };

  const validateInputs = () => {
    console.log(formValues);
    let error = {
      error_message: false
    };
    if (!formValues.name_form || formValues.name_form.length === 0) {
      error.error_message = "Full Name Is Required!";
    } else if (!formValues.email_form || formValues.email_form.length === 0) {
      error.error_message = "Email Is Required!";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.email_form)) {
      error.error_message = "Invalid email address!";
    } else if (!formValues.phone_form || formValues.phone_form.length === 0) {
      error.error_message = "Phone No. Is Required!";
    }
    return error;
  };

  return { handleInputChange, validateInputs, formValues };
};

export default UseFormValidation;
