import React from "react";
import "./footer.css";

const Footer = props => {
  let content = (
    <React.Fragment>
      <div className="container-fluid no-padding footer-bg">
        <div className="row footer-container">
          <div className="col-12 no-padding">
            Copyright © 2019 <br />
            <br />
            Oasis Corporate Park, No. 2, Jln PJU 1A/2, Ara Damansara, 47301 Petaling Jaya, Selangor
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  return content;
};

export default Footer;
